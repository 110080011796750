import React, { Component } from "react";
import { FILE_BASE_URL } from "../../config";
import store from "../../store";
import { setModalVisibility } from "../../store/slices/modals";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

class MainNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dimension: {
				width: window.innerWidth,
				height: window.innerHeight,
			},
			showMobileNav: false,
		};

		this.setDimension = this.setDimension.bind(this);
		this.closeMobileMenu = this.closeMobileMenu.bind(this);
		this.openMobileMenu = this.openMobileMenu.bind(this);
		this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
	}

	componentDidMount() {
		window.addEventListener("resize", this.setDimension);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.setDimension);
	}

	setDimension() {
		this.setState({
			dimension: { width: window.innerWidth, height: window.innerHeight },
		});
	}

	closeMobileMenu() {
		this.setState({ showMobileNav: false });
	}

	openMobileMenu() {
		this.setState({ showMobileNav: true });
	}

	toggleMobileMenu() {
		this.setState({ showMobileNav: !this.state.showMobileNav });
	}

	render() {
		const { menus } = this.props;
		const { showMobileNav, dimension } = this.state;

		if (dimension.width < 800) {
			return (
				<MobileNav
					menus={menus}
					showMobileNav={showMobileNav}
					onCloseMenuClick={this.closeMobileMenu}
				/>
			);
		}

		return <DesktopNav menus={menus} />;
	}
}

export default MainNav;

export const allDepartments = [
	{ text: "CE", href: "/ce", subMenus: null },
	{ text: "ME", href: "/me", subMenus: null },
	{ text: "ECE", href: "/ece", subMenus: null },
	{ text: "CSE", href: "/cse", subMenus: null },
	{ text: "EEE", href: "/eee", subMenus: null },
	{ text: "EIE", href: "/eie", subMenus: null },
	{ text: "CHE", href: "/che", subMenus: null },
	{ text: "IT", href: "/it", subMenus: null },
	{ text: "MTE", href: "/mt", subMenus: null },
	{ text: "Physics", href: "/phy", subMenus: null },
	{ text: "Chemistry", href: "/chem", subMenus: null },
	{ text: "Maths", href: "/maths", subMenus: null },
	{ text: "HSS", href: "/hss", subMenus: null },
//	{ text: "MBA", href: "/mba", subMenus: null },
];

export const menus = [
	{
		text: "Academics",
		href: "#",
		subMenus: [
			{
				text: "Academics",
				href: "https://sites.google.com/ptuniv.edu.in/ptudeanacademics",
				isNormalLink: true,
				subMenus: [
					{
						text: "Regulation",
						href: "https://sites.google.com/ptuniv.edu.in/ptudeanacademics/regulations",
						isNormalLink: true,
						subMenus: null,
					},
					{
						text: "Curriculum & Syllabus",
						href: "https://sites.google.com/ptuniv.edu.in/ptudeanacademics/curriculum",
						isNormalLink: true,
						subMenus: null,
					},

					{
						text: "Calendar and Circulars",
						href: "https://sites.google.com/ptuniv.edu.in/ptudeanacademics/calender-circulars",
						isNormalLink: true,
					},

					{
						text: "TimeTable",
						href: "https://sites.google.com/ptuniv.edu.in/ptudeanacademics/timetable",
						isNormalLink: true,
					},

					{
						text: "Nominal Roll",
						href: "https://sites.google.com/ptuniv.edu.in/ptudeanacademics/nominal-roll",
						isNormalLink: true,
					},
					{
						text: "Services",
						href: "https://sites.google.com/ptuniv.edu.in/ptudeanacademics/services",
						isNormalLink: true,
					},

					{
						text: "FAQ",
						href: "https://sites.google.com/ptuniv.edu.in/ptudeanacademics/faq",
						isNormalLink: true,
					},

					{
						text: "Contact",
						href: "https://sites.google.com/ptuniv.edu.in/ptudeanacademics/contact-us",
						isNormalLink: true,
					},
				],
			},
			{
				text: "Departments",
				href: "#",
				subMenus: allDepartments,
			},
			{
				text: "Continuing Education Program",
				href: "#",
				subMenus: null,
			},
		],
	},
	{
		text: "Research",
		href: "https://sites.google.com/ptuniv.edu.in/dor/home",
        		isNormalLink: true,
                linkProps: {
							target: "_dor",
						},
	
       // subMenus: [
		//	{
			//	text: "Research Facilities",
				//href: "#",
				//subMenus: null,
			//},
		//	{
		//		text: "MoUs",
		//		href: "#",
		//		subMenus: null,
		//	},
		//	{
		//		text: "IPRs",
		//		href: "#",
		//		subMenus: null,
		//	},
		//	{
		//		text: "Publications",
		//		href: "#",
		//		subMenus: null,
		//	},
		// ],
	},
	{
		text: "Innovation",
		href: "https://www.aicpecf.org/",
		isNormalLink: true,
		subMenus: [
			{
				text: "Institute Entrepreneurship And Development Cell (IEDC)",
				href: "https://www.ptuiedc.info",
				isNormalLink: true,
				linkProps: {
					target: "_blank",
				},
				subMenus: null,
			},
			{
				text: "AIC-PECF",
				href: "https://www.aicpecf.org",
				isNormalLink: true,
				linkProps: {
					target: "_blank",
				},
				subMenus: null,
			},
			{
				text: "Industrial Consultancy Services",
				href: "#",
				subMenus: null,
			},
			{
				text: "Institutions Innovation Council (IIC)",
				href: "https://iicptu.weebly.com/",
				isNormalLink: true,
				subMenus: null,
			},
		],
	},
	{
		text: "Admissions",
		href: "#",
		subMenus: [
			{
				text: "Undergraduate",
				isNormalLink: true,
				href: FILE_BASE_URL + "admissions/ugAdmission.html",
				subMenus: null,
			},
			{
				text: "Postgraduate",
				isNormalLink: true,
				href: FILE_BASE_URL + "admissions/pgAdmission.html",
				subMenus: null,
			},
			{
				text: "Ph.D",
				isNormalLink: true,
				href: FILE_BASE_URL + "admissions/phdAdmission.html",
				subMenus: null,
			},
		],
	},
	{
		text: "About PTU",
		href: "#",
		subMenus: [
			{
				text: "Overview",
				href: "#",
				subMenus: [
					{
						text: "Vision",
						href: "#",
						isNormalLink: true,
						linkProps: {
							onClick(e) {
								e.preventDefault();
								store.dispatch(
									setModalVisibility({ name: "main-vision", visibility: true })
								);
							},
						},
						subMenus: null,
					},
					{
						text: "Mission",
						href: "#",
						isNormalLink: true,
						linkProps: {
							onClick(e) {
								e.preventDefault();
								store.dispatch(
									setModalVisibility({ name: "main-mission", visibility: true })
								);
							},
						},
						subMenus: null,
					},
					{
						text: "Administration",
						href: FILE_BASE_URL + "ptu-administration.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
						text: "Strategic Plan",
						href: FILE_BASE_URL + "strategic-plan.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
						text: "PTU ACT",
						href: FILE_BASE_URL + "ptuact2019.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
						text: "PTU Bye laws",
						href: FILE_BASE_URL + "ptu-byelaws.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
						text: "PTU Code of Conduct",
						href: FILE_BASE_URL + "ptu-codeofconduct.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
				],
			},
			{
				text: "",
				href: "",
				subMenus: [
					{
						text: "AICTE Mandatory Disclosure",
						href:
							FILE_BASE_URL +
							"AICTE PTU_MANDATE DISCLOSURE_Version_8_July_2022.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
						text: "NIRF 2024 Data",
						href: FILE_BASE_URL + "NIRF_PEC_2024.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
						text: "AICTE Feedback",
						href: "https://www.aicte-india.org/feedback/index.php",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
						text: "AICTE EOAs",
						href: FILE_BASE_URL + "AICTE-PEC-EOAs.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
						text: "Audit Report",
						href: FILE_BASE_URL + "Audited Account Statement.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
						text: "RTI Act 2005",
						href: FILE_BASE_URL + "rtiPTUinfo.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					// {
					// 	text: "ICC",
					// 	href: FILE_BASE_URL + "ptuicc.pdf",
					// 	isNormalLink: true,
					// 	linkProps: {
					// 		target: "_blank",
					// 		title: "Internal Complaints Committee"
					// 	},
					// 	subMenus: null,
					// },
				],
			},
			// {
			// 	text: "Commitee",
			// 	href: "",
			// 	subMenus: [
			// 		{
			// 			text: "Anti Ragging Committee",
			// 			href: FILE_BASE_URL + "anti_ragging_committee.pdf",
			// 			isNormalLink: true,
			// 			linkProps: {
			// 				target: "_blank",
			// 			},
			// 			subMenus: null,
			// 		},
			// 		{
			// 			text: "Student Grievances and Redressal",
			// 			href: "/sgr",
			// 			isNormalLink: true,
			// 			linkProps: {
			// 				target: "_blank",
			// 			},
			// 			subMenus: null,
			// 		},
			// 		{
			// 			text: "Sexual Harassment - Gazette",
			// 			href: FILE_BASE_URL + "Gazette_Notification_Sexual_Harassment_of_Women_at_Workplace.pdf",
			// 			isNormalLink: true,
			// 			linkProps: {
			// 				target: "_blank",
			// 			},
			// 			subMenus: null,
			// 		},
			// 		{
			// 			text: "Sexual Harassment Prevention Act 2013",
			// 			href: FILE_BASE_URL + "Sexual_Harassment_of_Women_at_workplace_Act_2013.pdf",
			// 			isNormalLink: true,
			// 			linkProps: {
			// 				target: "_blank",
			// 			},
			// 			subMenus: null,
			// 		}
			// 	],
			// },
			{
				text: "Vital Statistics • NIRF rankings",
				href: "#",
				subMenus: null,
			},
			{
				text: "",
				href: "",
				subMenus: [
					{
						text: "IQAC",
						href: FILE_BASE_URL + "PTU-IQAC 2022-24.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
						text: "PTUAA",
						href: "https://pecaa.pec.edu/",
						isNormalLink: true,
						linkProps: {
							title: "PTU ALUMNI ASSOCIATION",
						},
						subMenus: null,
					},
					{
						text: "LeBanyandePEC",
						href: "https://LeBanyandePEC.org",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
							title: "Alumni NGO",
						},
						subMenus: null,
					},
					{
						text: "IIRS Outreach Programme",
						href: FILE_BASE_URL + "Website ISRO-IIRS.pdf",
						isNormalLink: true,
						linkProps: {
							target: "_blank",
						},
						subMenus: null,
					},
					{
					 	text: "Internal Complaints Committee",
					 	href: FILE_BASE_URL + "ptuicc.pdf",
					 	isNormalLink: true,
					 	linkProps: {
					 		target: "_blank"
					 	},
					 	subMenus: null,
					 },
				],
			},
			{
				text: "Campus Life",
				href: "#",
				subMenus: [
					{
						text: "Healthcare",
						href: FILE_BASE_URL + "campus-life/healthcare.html",
						isNormalLink: true,
						subMenus: null,
					},
					{
						text: "Hostels",
						href: FILE_BASE_URL + "campus-life/hostels.html",
						isNormalLink: true,
						subMenus: null,
					},
					{
						text: "Sports",
						href: FILE_BASE_URL + "campus-life/sports.html",
						isNormalLink: true,
						subMenus: null,
					},
					{
						text: "Central Library",
						href: FILE_BASE_URL + "ptulibrary.pdf",
						isNormalLink: true,
						subMenus: null,
					},
					{
						text: "Transport",
						href: "#",
						isNormalLink: true,
						subMenus: null,
					},
					{
						text: "Bank",
						href: "#",
						isNormalLink: true,
						subMenus: null,
					},
					{
						text: "Committees",
						href: "#",
						isNormalLink: true,
						subMenus: [
							{
								text: "Anti Ragging Committee",
								href: FILE_BASE_URL + "anti_ragging_committee.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Student Grievances and Redressal",
								href: "/sgr",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Students Disciplinary Action Committee",
								href: "#",
								isNormalLink: true,
								subMenus: null,
							}
						],
					},
					{
						text: "Cells",
						href: "#",
						isNormalLink: true,
						subMenus: [
							{
								text: "Anti-Narcotics Cell",
								href: "#",
								isNormalLink: true,
								subMenus: null,
							},
							{
								text: "Career Counselling and Guidance Cell",
								href: "#",
								isNormalLink: true,
								subMenus: null,
							},
							{
								text: "SC/ST cell",
								href: FILE_BASE_URL + "campus-life/SC%20ST%20cell%20activity%20report.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Women Cell",
								href: FILE_BASE_URL + "campus-life/Women%20Cell.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
						],					
					},
					{
						text: "Clubs",
						href: FILE_BASE_URL + "campus-life/clubs.html",
						isNormalLink: true,
						subMenus: [
							{
								text: "Literary Club",
								href: FILE_BASE_URL + "campus-life/Literary%20Club.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Computers and Multimedia Club",
								href: FILE_BASE_URL + "campus-life/Computers%20and%20Multimedia%20Club.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Health Club",
								href: FILE_BASE_URL + "campus-life/Health%20Club%20Report.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Institute Innovation Club",
								href: "",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Cultural Club",
								href: FILE_BASE_URL + "campus-life/Cultural%20Club.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Gender Championship Club",
								href: FILE_BASE_URL + "campus-life/Gender%20Champion%20Club.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Photography Club",
								href: FILE_BASE_URL + "campus-life/Photgraphy%20Club.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Fine Arts Club",
								href: FILE_BASE_URL + "campus-life/Fine%20Arts%20Club.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Design Club",
								href: "/4c/design-club",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "AI Club",
								href: "4c/ai-club",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "Sentamil Mandram",
								href: FILE_BASE_URL + "campus-life/TamilMandram.pdf",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							{
								text: "IOE Innovation Hub",
								href: "/4c/ioehub",
								isNormalLink: true,
								linkProps: {
									target: "_blank",
								},
								subMenus: null,
							},
							// {
							// 	text: "Sexual Harassment Prevention Act 2013",
							// 	href: FILE_BASE_URL + "Sexual_Harassment_of_Women_at_workplace_Act_2013.pdf",
							// 	isNormalLink: true,
							// 	linkProps: {
							// 		target: "_blank",
							// 	},
							// 	subMenus: null,
							// }
						],
					},
					{
						text: "NSS",
						href: "#",
						isNormalLink: true,
						subMenus: null,
					},
					{
						text: "Education Tour",
						href: "#",
						isNormalLink: true,
						subMenus: null,
					},
					{
						text: "Merit awards",
						href: "#",
						isNormalLink: true,
						subMenus: null,
					},
					// {
					// 	text: "Women's Forum",
					// 	href: FILE_BASE_URL + "campus-life/womens-forum.html",
					// 	isNormalLink: true,
					// 	subMenus: null,
					// },
					// {
					// 	text: "Student Activities",
					// 	href: FILE_BASE_URL + "campus-life/student-activities.html",
					// 	isNormalLink: true,
					// 	subMenus: null,
					// },
					// {
					// 	text: "Campus Amenities",
					// 	href: FILE_BASE_URL + "campus-life/campus-amenities.html",
					// 	isNormalLink: true,
					// 	subMenus: null,
					// },
					// {
					// 	text: "Campus Publications",
					// 	href: FILE_BASE_URL + "campus-life/campus-publications.html",
					// 	isNormalLink: true,
					// 	subMenus: null,
					// },
				],
			}
			// {
			// 	text: "Commitee",
			// 	href: "",
			// 	subMenus: [
			// 		{
			// 			text: "Anti-Raging Committee",
			// 			href: FILE_BASE_URL + "anti_ragging_2022.pdf",
			// 			isNormalLink: true,
			// 			linkProps: {
			// 				target: "_blank",
			// 			},
			// 			subMenus: [
			// 				{
			// 					text: "Test",
			// 					href: "#",
			// 					isNormalLink: true,
			// 					linkProps: {
			// 						target: "_blank",
			// 					},
			// 					subMenus: null,
			// 				},]
			// 		},
			// 		{
			// 			text: "Student Grievances and Redressal",
			// 			href: "/sgr",
			// 			isNormalLink: true,
			// 			linkProps: {
			// 				target: "_blank",
			// 			},
			// 			subMenus: null,
			// 		},
			// 		{
			// 			text: "Sexual Harassment - Gazette",
			// 			href: FILE_BASE_URL + "Gazette_Notification_Sexual_Harassment_of_Women_at_Workplace.pdf",
			// 			isNormalLink: true,
			// 			linkProps: {
			// 				target: "_blank",
			// 			},
			// 			subMenus: null,
			// 		},
			// 		{
			// 			text: "Sexual Harassment Prevention Act 2013",
			// 			href: FILE_BASE_URL + "Sexual_Harassment_of_Women_at_workplace_Act_2013.pdf",
			// 			isNormalLink: true,
			// 			linkProps: {
			// 				target: "_blank",
			// 			},
			// 			subMenus: null,
			// 		}
			// 	],
			// }	
		],
	},
];

export function MainNavContainer({ navRef }) {
	return <MainNav menus={menus} ref={navRef} />;
}
