import { FILE_BASE_URL, SYLLABUS_BASE_URL } from "../../../config";

import Image from "../../../../src/components/assets/CHE_HOD.jpg";

import Dimg from "../../../../src/components/assets/chem.jpg";

export const Che = {
	basePath: "/che",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			to: "/research",
			text: "RESEARCH",
		},
		{
			href: FILE_BASE_URL + "che-facilities.html",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "Chemical engineering",
		ShortName: "CHE",
		LongName: "DEPARTMENT OF CHEMICAL ENGINEERING",

		DeptImg: Dimg,

		count: [
			{
				name: "B.Tech Students",
				count: 170,
			},
		],

		about: [
			`The Department of chemical engineering was established in the year 1998 with a vision to impart high quality chemical engineering education and to serve the needs of industries and societies through technical services, consultancy and research. This department is only the sixth chemical engineering department in the Government/Government aided sector in the region of Tamil Nadu and Puducherry.`,
			`The department offers a B.tech course in Chemical Engineering with an annual intake of 30 students in first year and 6 students in second year through lateral entry scheme. The department jointly with the Department of Civil Engineering is also offering an M.Tech course in Environmental Engineering from the academic year 2003-2004. The Department is steered to achieve the pinnacles of excellence by a team of highly qualified and dedicated faculty members, whose area of specialisation include petroleum and petrochemicals, process control and simulation, energy technology, process engineering and design.`,
		],

		vision: [
			{
				point:
					"To become a Department that Imparts High Quality Education to its Graduates and prepares them to be leaders in Chemical Engineering and Allied Fields.",
			},
		],

		Mission: [
			{
				point:
					"To Provide Strong Technical Foundation in the Field of Chemical Engineering",
			},
			{
				point:
					"To Prepare the Students Coming from Different Socio Economic Levels for a Successful Career in Chemical and Allied Engineering Fields",
			},
			{
				point:
					"To make Professional Leaders, Academicians and Engineers with the highest moral values and ethics.",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "che-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr. R. Sridar",
			designation: "Professor and Head",
			img: Image,
			phnNo: "9894757209",
			email: "hod.che@ptuniv.edu.in",
			content: `The Department of Chemical Engineering is the only department both in the Govt. and
			Private Institutions offering Chemical Engineering in the region of Puducherry. The
			department has a small team of dedicated faculty members specialized in Process Systems
			Engineering and Control, Plant Design and Membrane Separations, Process Design and
			Supercritical Fluids, Petroleum Refining and Petrochemicals, Environmental Engineering
			and Bio-chemical Engineering. With its expertise, the department has been able to
			significantly contribute to the Government of Puducherry departments like the DSTE and the
			Civil Supplies and Consumer Affairs (DCS &amp; CA). The department has well equipped
			laboratory facilities which can give good hands on experience to its undergraduate students.
			Alumni of the department occupying coveted positions in Academia (IIT, NIT, IIPE, and in
			some US Universities) and Industries (IOCL, BARC, IGCAR, EIL, RIL, BIOCON, IDEA,
			ESSAR etc.,) is an asset to the department. The department has the potential to make a
			significant contribution in the area of Process Modeling and Simulation in particular and in
			the other niche areas of Chemical Engineering in general. I am happy to share a great
			moment of happiness and pride as the department is stepping into its 25 th year (Academic
			Year 2022-23) and gearing up for its Silver Jubilee celebration with the launching of a post
			graduate and a Ph.D programmes. I am sure that the department is poised to create a
			positive impact not only in the region of Puducherry but also at the National/International
			arena.`,
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/che-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/che-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/che-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/che-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/che-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/che-events.pdf",
		},
	],
	Research: [
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
	],
	curriculum: [
		{
			name: "B.Tech",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "CHE_Cur_20-21.pdf",
				},
			],
		},
	],
	facilities: [],
};