import Image from "../../../../src/components/assets/MBA_HOD.png";
import { FILE_BASE_URL } from "../../../config";

export const MBA = {
	basePath: "/mba",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			to: "/research",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "MASTER OF BUSINESS ADMINISTRATION",
		ShortName: "MBA",
		LongName: "DEPARTMENT OF MASTER OF BUSINESS ADMINISTRATION",

		DeptImg: "",

		count: [
			{
				name: "MBA Students",
				count: 16,
			},
		],

		about: [
			`MBA in Innovation, Entrepreneurship & Venture Development was started in the year 2020. The programme comprises of core and elective courses, skill certification, capstone projects and Action Learning Segments. With an objective to connecting theory of innovation and entrepreneurship with practice and enabling students to acquire skills, this outcome oriented program is designed to deliver in phases and modules. This will facilitate learning on management of innovation, entrepreneurship and venture development from foundation to advanced stages and simultaneously enable students to apply their entrepreneurial skills in real life situations, validate ideas, build innovations, test market and build a successful venture out of it.
        	 MBA in International Business was started in the year 2021. It is a specialisation that focuses on training the candidate in the basic skills and knowledge of international business, trade, import, export, laws, policies, etc. to conduct business at a global platform. After completing this course, candidates will have a plethora of career alternatives. Because of the expanding globalisation and the expanding number of businesses playing on the world stage, international business degree graduates are constantly in demand.`,
		],

		vision: [
			{
				point: "",
			},
			{
				point: "",
			},
		],

		Mission: [
			{
				point: "",
			},
			{
				point: "",
			},
			{
				point: "",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "mba-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr. F. Sagayaraj Francis",
			designation: "Professor and Head",
			img: Image,
			// phnNo: "",
			// email: "",
			// content: "",
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/mba-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/mba-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/mba-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/mba-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/mba-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/mba-events.pdf",
		},
	],
	Research: [
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
	],
	curriculum: [
		{
			name: "MBA",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: "MBA_IEV_Syllabus.pdf",
				},
				{
					name: "AY 2020-21",
					link: "MBA_International_Business_syllabus.pdf",
				},
			],
		},
	],
	facilities: [],
};
